@import url('https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900');

$bg-gradient: linear-gradient(to right, #24243e, #141e30, #0f0c29);

@font-face {
  font-family: Helvetica;
  src: url('./fonts/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: Helvetica;
  src: url('./fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: Helvetica;
  src: url('./fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Helvetica;
  src: url('./fonts/HelveticaNeueCyr-Heavy.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Helvetica;
  src: url('./fonts/HelveticaNeueCyr-Black.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: PeaceSans;
  src: url('./fonts/PeaceSans.otf') format('opentype');
}
@font-face {
  font-family: ArtSome;
  src: url('./fonts/ArtSomeAlternate-Heavy.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
}

html,
body {
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  text-decoration: none;
  list-style: none;
  font-size: 10px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

#stars {
  position: relative;
  background-color: #141e30;
  background: $bg-gradient;
  width: 100%;
  opacity: 0.25;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
ul li {
  list-style: none;
}
a {
  list-style: none;
  text-decoration: none;
  &:visited {
    color: #fff;
  }
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
}

//START HEADER MENU
@keyframes depthAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shine {
  0% {
    background-position: -180px;
  }
  100% {
    background-position: 180px;
  }
}

.colum__header {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  padding: 20px;
  width: 100%;
  transform: translateX(50%);
  right: 50%;
  max-width: 1300px;
  z-index: 1;
  .header__logo {
    font-size: 20px;
    color: #fff;
    h2 {
      display: flex;
      align-items: baseline;
      font-family: ArtSome, sans-serif;
      strong {
        text-transform: uppercase;
        font-size: 6rem;
        margin-right: 5px;
        color: #e4e5e6;
        position: relative;
        // background: linear-gradient(-45deg, #3a3f79, #8272b1, #fcbbb4);
        background: linear-gradient(to right, #9696ff, #6b9efd, #5c4bfc);
        background-size: 400% 400%;
        animation: depthAnimation 10s ease infinite;

        background-clip: text;
        -webkit-text-fill-color: transparent;

        &:before,
        &:after {
          content: attr(data-text);
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {
          z-index: -1;
          text-shadow: -0.001em -0.001em 1px rgba(255, 255, 255, 0.15);
        }

        &:after {
          z-index: -2;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5),
            3px 3px 2px rgba(0, 0, 0, 0.4), 5px 5px 5px rgba(0, 0, 0, 0.1);
          mix-blend-mode: multiply;
        }
      }
      p {
        color: #fff;
        background: linear-gradient(to right, #fff 0, #9696ff 6%, #fff 12%);
        background-position: 0;
        background-size: 200% 200%;
        background-clip: text;
        -webkit-background-clip: text;
        font-family: PeaceSans;
        letter-spacing: 0.3em;
        -webkit-text-fill-color: transparent;
        animation: shine 7s infinite ease-in;
        animation-fill-mode: forwards;
        -webkit-text-size-adjust: none;
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }
  .header__menu {
    .header__menu-container {
      .header__top-menu {
        ul {
          display: flex;
          list-style: none;
          li {
            padding: 10px 30px;
            a {
              font-size: 19px;
              font-weight: 700;
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .header__text {
    display: flex;
    flex-direction: column;
    a {
      color: #fff;
      text-align: right;
    }
  }
}

header {
  position: relative;
  background: linear-gradient(to right, #24243e, #141e30, #0f0c29);
}
.header__top {
  position: absolute;
  z-index: 1;
  transform: translate(50%, 50%);
  right: 50%;
  bottom: 50%;
  width: 70%;
}
.header__title {
  text-align: center;
  h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 78px;
    color: #fff;
  }
}
//END HEADER MENU
//START NAV MENU
.header__top-menu {
  z-index: 10000;
  background-color: #000;
}
p {
  text-align: center;
}

nav {
  z-index: 999999;
  margin-left: 150px;
  a {
    display: block;
    padding: 0 34px;
    color: #fff;
    font-size: 2rem;
    line-height: 7.1rem;
    text-decoration: none;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    li {
      display: inline-block;
      a:hover {
        color: #d9d9d9;
      }
    }
  }
}

/* Hide Dropdowns by Default */

nav ul ul {
  display: none;
  position: absolute;
  top: 60px; /* the height of the main nav */
  border-top: 3px #d3d0cb solid;
}
nav ul li li a {
  color: #fff;
}

/* Display Dropdowns on Hover */
nav ul li:hover > ul {
  display: inherit;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  float: none;
  display: list-item;
  position: relative;
}

/* Second, Third and more Tiers	*/
nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}
//END NAV MENU

//START BREADCRUMBS

.breadcrumbs {
  max-width: 1120px;
  width: 90%;
  &__inner {
    padding: 45px 0px 0px;
    overflow: hidden;
    width: 100%;
    margin-right: auto;
  }
  &__list {
    li {
      display: inline-block;
      position: relative;
      & + li {
        padding-left: 24px;
      }
      &:last-child {
        a,
        span {
          color: #000;
        }
      }
      a {
        color: #b0bec5;
        font-size: 18px;
      }
    }
  }
}
//END BREADCRUMBS

//START FOOTER
.footer {
  background-color: #f5f5f5;
  &__inner {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.footer__copy {
  border-top: 1px solid #424d53;
}
.footer__copy-text {
  text-align: center;
  font-size: 16px;
  color: #919699;
  font-weight: 400;
  padding: 10px 10px;
}
.footer__col {
  max-width: 335px;
  width: 100%;
  &-menu {
    max-width: 230px;
  }
  &-title {
    font-size: 22px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #000;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2px;
      width: 51px;
      height: 3px;
      background-color: #000;
    }
  }
  &-text {
    color: #b7c3c8;
    margin-bottom: 30px;
  }
}
.footer__info {
  font-family: Helvetica;
  width: 300px;
  padding: 10px 0;
  color: #000;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-decoration: none;
  transition: 0.2s;
  a {
    text-decoration: none;
    color: #000;
  }
}
.footer__col-list {
  list-style: none;
  li {
    padding: 4px 0;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 15px;
    color: #000;
    position: relative;
    transition: 0.2s;
  }
  a:hover {
    color: #919699;
    transition: 0.2s;
  }
}
.footer__info-title {
  margin-bottom: 11px;
}
.footer__social-link {
  display: flex;
  li {
    display: inline-block;
    margin-left: 7px;
  }
  a {
    width: 28px;
    height: 28px;
    color: #000;
    & + a {
      margin-left: 7px;
    }
  }
}
.link-twitter {
  background-position: -28px 0;
}
.link-youtobe {
  background-position: -56px 0;
}
.link-pinterest {
  background-position: -84px 0;
}
.link-linkedin {
  background-position: -112px 0;
}
.footer__form-title {
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
}
.footer__form {
  position: relative;
  input {
    border: none;
    background-color: #2f3c43;
    display: block;
    width: 100%;
    height: 40px;
    color: #fff;
    font-size: 14px;
    padding-left: 13px;
    padding-right: 50px;
    &::placeholder {
      color: #697378;
    }
  }
  button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #427f8c;
    border: none;
    height: 40px;
    width: 43px;
    cursor: pointer;
    &::before {
      content: '\f124';
      position: absolute;
      font-family: 'icomoon';
      color: #fff;
      font-size: 20px;
      top: 9px;
      left: 13px;
    }
  }
}

.footer__logo {
  h2 {
    font-size: 2.7rem;
    color: rgb(0, 25, 25);
    font-family: PeaceSans;
    strong {
      font-family: ArtSome;
      color: #505086;
      font-size: 4rem;
      color: #e4e5e6;
      background: linear-gradient(to right, #9696ff, #6b9efd, #5c4bfc);
      background-size: 400% 400%;
      animation: depthAnimation 10s ease infinite;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.fa {
  border: 2px #000 solid;
  border-radius: 50%;
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  box-sizing: initial;
}

.fa-facebook,
.fa-instagram {
  background: #fff;
  transition: 0.2s;
}
.fa-facebook:hover,
.fa-instagram:hover {
  background: #000;
  color: #fff;
  transition: 0.2s;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  // -webkit-background-clip: text;
  // /* Also define standard property for compatibility */
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.fa-telegram {
  background: #fff;
}

.fa-telegram:hover {
  border-color: #fff;
  background: #ff7733;
  filter: invert(1);
}

.footer__info-title {
  font-weight: 700;
  margin-right: 15px;
}
//  END FOOTER

//  CONTENT START
//  START ABOUT_US
.about_us-inner {
  .about_us-inner_title {
    padding-top: 100px;
    h2 {
      font-size: 52px;
      color: #262626;
    }
  }
  .about_us-text {
    padding-top: 25px;
    ul {
      font-size: 18px;
      color: #000;
      li {
        padding: 10px 0;
        padding-left: 35px;
        text-indent: -1.8em;

        &::before {
          content: '—';
          padding-right: 15px;
        }
      }
    }
  }
}
//END ABOUT_US

//START SERVICES
.services {
  margin: 50px 0;
  .services__inner-title {
    h2 {
      font-size: 52px;
      color: #262626;
    }
  }
  .services__items-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .services__items-inner {
    text-align: center;
    margin: 20px 20px;
    padding-top: 40px;
    width: 300px;
    height: 300px;
    transition: 0.6s;

    img {
      width: 150px;
      height: 150px;
      margin: 10px 10px;
    }
    p {
      font-size: 23px;
      color: #000;
    }
  }
}
//END SERVICES

//START ABOUT_US

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
@keyframes wipe-enter {
  0% {
    opacity: 0;
    transform: scale(0.025, 0);
  }
  50% {
    opacity: 1;
    transform: scale(0.025, 1);
  }
}
.about__us {
  // font-weight: lighter;
  background: #24243e;
  background: $bg-gradient;
  padding-bottom: 50px;
  .about__us-content {
    .about__us-inner {
      .about__us-title {
        padding-top: 100px;
        h2 {
          font-size: 5rem;
          color: #fff;
        }
      }
    }
    .about__us-wrap {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      font-size: 2rem;
      .about__us-row {
        display: flex;
        padding: 20px 20px;
        margin: 50px 0;
        flex: 0 50%;
        .number {
          color: #fff;
        }
        .text {
          overflow: hidden;
          padding-left: 15px;

          h3 {
            color: #fff;
            padding-bottom: 15px;
          }
          p {
            color: #fff;
            text-align: left;
          }
        }
      }
    }
  }
}
//END ABOUT_US

//START FEEDBACK
.feedback {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
}
.feedback__title {
  text-align: center;
  h2 {
    font-size: 54px;
    line-height: 56px;
    font-weight: 700;
  }
  img {
    margin-top: 75px;
    height: auto;
    width: 100%;
  }
}
.page {
  max-width: 400px;
  width: 100%;
  padding: 50px;
  // border: 1px solid #cbd1d4;
  margin-left: auto;
  margin-right: auto;
}

.page-msg {
  display: block;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}

.form-group:nth-child(n + 2) {
  margin-top: 30px;
}

.form-actions {
  text-align: center;
  button {
    border-radius: 5px;
  }
}

.field-label {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin: 0 auto;
  margin-bottom: 12px;
}

.textarea {
  max-width: 400px;
  min-width: 300px;
  min-height: 100px;
}

.field {
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: 300px;
  // border: 2px solid #dadada;
  border: none;
  // box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px -8px #ffffff;
  // background: linear-gradient(135deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25));
  // box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.25),
  //  -4px -4px 12px 0 rgba(255, 255, 255, 0.3);
  -webkit-appearance: none;
  box-shadow: -4px -4px 6px 0 rgba(0, 0, 0, 0.1),
    6px 6px 8px rgba(255, 255, 255, 0.1), 4px 4px 6px 0 rgba(0, 0, 0, 0.1),
    -6px -6px 8px rgba(255, 255, 255, 0.1), -4px 4px 6px 0 rgba(0, 0, 0, 0.1),
    6px -6px 8px rgba(255, 255, 255, 0.1), 4px -4px 6px 0 rgba(0, 0, 0, 0.1),
    -6px 6px 8px rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin: 0 auto;
  font-size: 13px;
  padding: 10px 20px;
  height: 50px;
  background-color: #ffffff;
}

.button {
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.button-clear {
  background-color: transparent;
  color: #000;
  border: 1px #000 solid;
  margin-right: 5px;
}

.button-submit {
  color: #000;
  background-color: #fff;
  max-width: 70%;
  width: 100%;
  border: 1px #000 solid;
}

.field:focus,
.button:focus {
  // border: 1px solid #2f4f4f;
  border: 2px solid #aaaaff;
  outline: none;
}

.button:hover {
  background-color: #000;
  transition: 0.5s;
  // border: 1px #2f4f4f solid;
  color: #fff;
}

.button:active {
  transform: scale(0.97);
  transition: 0.01s;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sending {
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  // background: $bg-gradient;
  background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  color: #fff;
  border: 1px solid black;
}

.sent {
  // background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100% );
  background-image: linear-gradient(
    to right,
    #28a746 0%,
    #84faaf9a 51%,
    #28a745 100%
  );
  background-color: #28a745;
  color: #fff;
}

.error-sending {
  background-color: #f08080;
}

//END FEEDBACK

//START PORTFOLIO
.portfolio {
  background: $bg-gradient;
  padding-top: 100px;
  padding-bottom: 100px;
  .portfolio__title {
    text-align: center;
    color: #fff;
    font-size: 54px;
    line-height: 56px;
    font-weight: 700;
  }
  .portfolio__box {
    .portfolio__wrap {
      .wrap {
        margin: 50px auto 0 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        align-items: space-around;
        justify-content: space-evenly;
        max-width: 1200px;
      }
      .tile {
        min-width: 320px;
        min-height: 380px;
        margin: 10px;
        font-size: 1.2rem;
        background-size: cover;
        position: relative;
        transition: all 0.4s ease-out;
        box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
        overflow: hidden;
        color: white;
        font-family: 'Roboto';
        img {
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
          z-index: 0;
          transition: all 0.4s ease-out;
        }
        .text {
          position: absolute;
          padding: 30px;
          height: 100%;

          &::before {
            content: ' ';
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            top: 0;
            width: 100%;
            height: 80px;
            background-color: rgba(50, 50, 50, 0.5);
            background: linear-gradient(rgba(0, 0, 0, 0.75), transparent);
          }
        }
        .more-info {
          display: block;
          position: absolute;
          bottom: 50px;
          cursor: pointer;
        }
        h2 {
          font-weight: 300;
          margin: 0;
          position: relative;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
        }
        h3 {
          font-weight: 100;
          margin: 20px 0 0 0;
          font-style: italic;
          transform: translateX(200px);
        }
        p {
          font-weight: 300;
          font-size: 1.6rem;
          margin: 20px 0 0 0;
          line-height: 25px;
          transform: translateX(-200px);
          transition-delay: 0.2s;
          text-align: left;
        }
        &:hover {
          box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
          transform: scale(1.05);
        }
        &:hover img {
          opacity: 0.2;
        }
        &:hover .animate-text {
          transform: translateX(0);
          opacity: 1;
        }
      }
      .animate-text {
        opacity: 0;
        transition: all 0.6s ease-in-out;
      }
      .dots {
        position: absolute;
        bottom: 20px;
        right: 30px;
        margin: 0 auto;
        width: 30px;
        height: 30px;
        color: currentColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }

      .dots span {
        width: 5px;
        height: 5px;
        background-color: currentColor;
        border-radius: 50%;
        display: block;
        opacity: 0;
        transition: transform 0.4s ease-out, opacity 0.5s ease;
        transform: translateY(30px);
      }

      .tile:hover span {
        opacity: 1;
        transform: translateY(0px);
      }

      @media (max-width: 1000px) {
        .wrap {
          flex-direction: column-reverse;
          width: 400px;
        }
      }
    }
  }
}
//END PORTFOLIO

@import 'mobile.scss';

@media only screen and (max-width: 768px) {
  .colum__header {
    // height: 100vh;
    min-height: -webkit-fill-available;
  }

  .footer__social-link {
    justify-content: center;
  }

  .header__title h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .header__top {
    width: 100%;
  }

  .colum__header {
    flex-direction: column;
    .header__menu {
      display: none;
    }
    .header__text {
      align-items: center;
      a {
        padding: 5px;
      }
    }
  }

  .services {
    margin: 40px 0;
  }
  .about_us-inner .about_us-inner_title,
  .feedback,
  .footer__inner {
    padding: 40px 0;
  }
  .about_us-inner_title,
  .services__inner-title,
  .about__us-title {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }

  .about_us-text {
    padding: 10px 5px;
    p {
      text-align: justify;
    }
  }

  .services .services__items-box {
    justify-content: center;
  }
  .services .services__items-inner {
    width: 250px;
    height: 250px;
  }
  .about__us .about__us-content .about__us-wrap {
    justify-content: unset;
  }
  .feedback__title img {
    display: none;
  }
  .feedback {
    flex-direction: column;
  }
  .page {
    padding: 20px;
    .form-actions {
      text-align: center;
    }
  }
  .portfolio .portfolio__box .portfolio__wrap {
    .tile {
      width: unset;
      max-width: 380px;
    }
    .wrap {
      width: 100%;
      max-width: 400px;
    }
  }
  .footer__inner {
    flex-direction: column;
    align-items: center;
  }
  .footer__logo h2 {
    text-align: center;
  }
  .footer__col.footer__col-menu {
    display: none;
  }
  .footer__col {
    max-width: 400px;
    text-align: center;
  }
  .footer__info {
    margin: 0 auto;
    text-align: left;
  }
  .footer__col-title::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .footer__col-title {
    padding: 10px 0;
  }
  .footer__logo {
    padding: 20px 0;
  }
  .about_us-inner .about_us-inner_title h2,
  .services .services__inner-title h2,
  .feedback__title h2,
  .portfolio .portfolio__title,
  .about__us .about__us-content .about__us-inner .about__us-title h2 {
    font-size: 40px;
  }
  .about__us .about__us-content .about__us-wrap .about__us-row {
    margin: 20px 0;
    flex: 0 100%;
  }
  .services .services__items-inner {
    width: 140px;
    p {
      font-size: 20px;
    }
  }
  .services .services__items-inner img {
    width: 100px;
    height: 100px;
  }
  .portfolio {
    padding: 40px 0;
  }
}
